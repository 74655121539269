import {graphql, Link} from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = () => {
  return (
    <Layout>
      <h1>404: Not Found</h1>
      <p>
        You just hit a route that doesn&#39;t exist... the sadness.{' '}
        <Link to="/">Home</Link>
      </p>
    </Layout>
  );
};

export const Head = () => (
  <Seo title="404: Not Found" description="Page not found" />
);

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

import {ReactNode} from 'react';
import Footer from './footer';
import Logo from './logo';

interface Props {
  children: ReactNode;
  className?: string;
}

const Layout = ({children, className}: Props) => {
  return (
    <div className={`global-wrapper ${className}`}>
      <header className="global-header">
        <Logo />
      </header>
      <main>{children}</main>
      <div className="mt-16">
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
